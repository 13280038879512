
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Header as AppHeader, SidebarToggler, AsideToggler } from '@coreui/vue';
import DefaultHeaderDropdownAccnt from '@/components/containers/DefaultHeaderDropdownAccnt.vue';
import HeaderDropdownProfile from '@/components/containers/HeaderDropdownProfile.vue';
import HeaderDropdownCompany from '@/components/containers/HeaderDropdownCompany.vue';
import accountx from '@/store/modules/accountx';
import moment from 'moment';

@Component({
	components: {
		AsideToggler,
		AppHeader,
		DefaultHeaderDropdownAccnt,
		HeaderDropdownProfile,
		HeaderDropdownCompany,
		SidebarToggler,
	},
})
export default class DefaultHeader extends Vue {
	public get fullAccountData() {
		return accountx.fullAccountData;
	}

	public get expireDate() {
		if (!this.fullAccountData || !this.fullAccountData.token) {
			return false;
		}
		return moment(this.fullAccountData.expiryTime).format('DD MMM YYYY');
	}

	public get expired() {
		if (!this.fullAccountData || !this.fullAccountData.token) {
			return false;
		}
		return this.fullAccountData.token.forbidden;
	}

	public get planType() {
		if (!this.fullAccountData || !this.fullAccountData.token) {
			return false;
		}
		return this.fullAccountData.programId === 'sql-dashboard'
			? 'Starter'
			: 'Premium';
	}

	public get daysRemaining() {
		if (!this.fullAccountData || !this.fullAccountData.token) {
			return false;
		}
		return moment(this.fullAccountData.expiryTime).diff(moment(), 'd') > 0
			? moment(this.fullAccountData.expiryTime).diff(moment(), 'd')
			: 0;
	}
}
