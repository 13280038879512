
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import userx from '@/store/modules/userx';

@Component({
	components: {},
})
export default class HeaderDropdownProfile extends Vue {
	public get user() {
		return userx.user;
	}
	public get displayName() {
		return userx.displayName;
	}
	public get supportLvl() {
		return userx.supportLvl;
	}
}
