
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import userx from '@/store/modules/userx';
import accountx from '@/store/modules/accountx';
import Dropdown from 'primevue/dropdown';
import Badge from 'primevue/badge';

@Component({
	components: { Dropdown, Badge },
})
export default class HeaderDropdownCompany extends Vue {
	@Prop({ default: false }) public readonly right!: boolean;

	public selectedCompany: any = '';

	@Watch('currentAccount', { immediate: true })
	public onCurrentAccountChanged(newVal) {
		this.selectedCompany = newVal.accountId ? newVal.accountId : newVal;
	}

	public get currentAccount() {
		return accountx.currentAccount;
	}

	public onRouteTo(accountId) {
		this.$router.push(`/account/${accountId}`).catch(() => {});
	}

	public get companyName() {
		return this.currentAccount && this.currentAccount.companyName;
	}

	public get numUnvisitedAccounts() {
		return userx.numUnvisitedAccounts;
	}

	public get allAccounts() {
		return userx.myAccounts;
	}
}
