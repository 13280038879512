
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
	Sidebar as AppSidebar,
	SidebarFooter,
	SidebarForm,
	SidebarHeader,
	SidebarMinimizer,
	SidebarNavItem,
	SidebarNavLink,
	Aside as AppAside,
	Breadcrumb,
} from '@coreui/vue';
import SidebarNav from './SidebarNav.vue';
import DefaultAside from './DefaultAside.vue';
import DefaultHeader from './DefaultHeader.vue';
import DefaultFooter from './DefaultFooter.vue';
import accountx from '@/store/modules/accountx';
import VueRouter, { Route, RawLocation } from 'vue-router';
import userx from '@/store/modules/userx';
import HeaderDropdownCompany from './HeaderDropdownCompany.vue';
import resize from 'vue-resize-directive';
import router from '@/router';

@Component({
	directives: {
		resize,
	},
	components: {
		AppSidebar,
		SidebarForm,
		SidebarFooter,
		SidebarHeader,
		SidebarNav,
		SidebarNavItem,
		SidebarNavLink,
		SidebarMinimizer,
		DefaultFooter,
		DefaultHeader,
		HeaderDropdownCompany,
	},
})
export default class DefaultContainer extends Vue {

	public get small() {
		return this.width < 540;
	}
	public get name() {
		return this.$route.name;
	}
	public get list() {
		const accountId = this.$route.params.accountId;
		const accountName = this.currentAccount && this.currentAccount.companyName;
		const name = accountName || accountId;
		return [{ name, path: `/account/${accountId}` }, this.$route.matched[1]];
		// return this.$route.matched.filter((route) => route.name || route.meta.label);
	}
	public get isAdmin() {
		if (!accountx.myPermissions) {
			return false;
		}
		return accountx.myPermissions.admin;
	}

	public get currentAccount() {
		return accountx.currentAccount;
	}

	public get currentAccountId() {
		return accountx.currentAccountId;
	}

	public get fullAccountData() {
		return accountx.fullAccountData;
	}

	public get sidebarList() {
		let items: any[] = [
			{
				title: true,
				name: 'Company Account',
				class: '',
				wrapper: {
					element: '',
					attributes: {},
				},
			},
		];

		if (this.fullAccountData) {
			const accountId = this.fullAccountData.accountId;

			const isExcludeAllModules =
				this.excludeAllAccountIdList.includes(accountId);

			if (
				!isExcludeAllModules &&
				!this.excludeDashboardModuleAccountIds.includes(accountId)
			) {
				items.push({
              name: 'Custom',
              url: `/account/${accountId}/custom`,
              icon: 'fas fa-warehouse',
            }, {
					name: 'Dashboard',
					url: `/account/${accountId}/dashboard`,
					icon: 'fas fa-tachometer-alt ',
				});
			}

			if (
				!isExcludeAllModules &&
				!this.excludeSalesModuleAccountIds.includes(accountId)
			) {
				items.push({
					name: 'Sales',
					url: `/a`,
					icon: 'fa fa-chart-area',
					children: [
						{
							name: 'Overview',
							url: `/account/${accountId}/sales-overview`,
							icon: 'fa fa-chart-pie',
						},
						{
							name: 'Progress',
							url: `/account/${accountId}/sales-progress`,
							icon: 'fa fa-spinner',
						},
						{
							name: 'Growth',
							url: `/account/${accountId}/sales-growth`,
							icon: 'fa fa-chart-line',
						},
						{
							name: 'Analysis',
							url: `/account/${accountId}/sales-analysis`,
							icon: 'fa fa-chart-bar',
						},
            {
              name: 'Delivery Planning',
              url: `/account/${accountId}/sales-delivery`,
              icon: 'fa fa-car',
            },
					],
				});
			}

			if (
				!isExcludeAllModules &&
				!this.excludeAgentModuleAccountIds.includes(accountId)
			) {
				items.push({
					name: 'Agent',
					url: `/account/${accountId}/agent`,
					icon: 'fa fa-user-tie',
					children: [
						{
							name: 'Overview',
							url: `/account/${accountId}/agent-overview`,
							icon: 'fa fa-chart-pie',
						},
					],
				});
			}

			if (
				!isExcludeAllModules &&
				!this.excludeStockModuleAccountIds.includes(accountId)
			) {
				items.push({
					name: 'Stock',
					url: `/account/${accountId}/stock`,
					icon: 'fa fa-boxes',
					children: [
						{
							name: 'Overview',
							url: `/account/${accountId}/stock-overview`,
							icon: 'fa fa-chart-pie',
						},
						{
							name: 'Analysis',
							url: `/account/${accountId}/stock-analysis`,
							icon: 'fa fa-chart-bar',
						},
					],
				});
			}

			if (
				!isExcludeAllModules &&
				!this.excludeCustomerModuleAccountIds.includes(accountId)
			) {
				items.push({
					name: 'Customer',
					url: `/account/${accountId}/customer`,
					icon: 'fa fa-user-tie',
					children: [
						{
							name: 'Overview',
							url: `/account/${accountId}/customer-overview`,
							icon: 'fa fa-chart-pie',
						},
						{
							name: 'Analysis',
							url: `/account/${accountId}/customer-analysis`,
							icon: 'fa fa-chart-bar',
						},
					],
				});
			}

			if (
				!isExcludeAllModules &&
				!this.excludePurchaseModuleAccountIds.includes(accountId)
			) {
				items.push({
					name: 'Purchase',
					url: `/account/${accountId}/purchase`,
					icon: 'fa fa-shopping-cart',
					children: [
						{
							name: 'Overview',
							url: `/account/${accountId}/purchase-overview`,
							icon: 'fa fa-chart-pie',
						},
						{
							name: 'Analysis',
							url: `/account/${accountId}/purchase-analysis`,
							icon: 'fa fa-chart-bar',
						},
					],
				});
			}

			if (
				!isExcludeAllModules &&
				!this.excludeSupplierModuleAccountIds.includes(accountId)
			) {
				items.push({
					name: 'Supplier',
					url: `/account/${accountId}/supplier`,
					icon: 'fa fa-user-alt',
					children: [
						{
							name: 'Overview',
							url: `/account/${accountId}/supplier-overview`,
							icon: 'fa fa-chart-pie',
						},
						{
							name: 'Analysis',
							url: `/account/${accountId}/supplier-analysis`,
							icon: 'fa fa-chart-bar',
						},
					],
				});
			}

			if (
				!isExcludeAllModules &&
				!this.excludeBPModuleAccountIds.includes(accountId)
			) {
				items.push({
					name: 'Business Performance',
					url: `/account/${accountId}/business-performance`,
					icon: 'fa fa-percent',
					children: [
						{
							name: 'P/L',
							url: `/account/${accountId}/profit-loss`,
							icon: 'fa fa-chart-pie',
						},
						{
							name: 'Balance Sheet',
							url: `/account/${accountId}/balance-sheet`,
							icon: 'fa fa-balance-scale',
						},
						{
							name: 'Cash Flow',
							url: `/account/${accountId}/cash-flow`,
							icon: 'fa fa-money-bill-wave',
						},
						{
							name: 'Financial Ratio',
							url: `/account/${accountId}/financial-ratio`,
							icon: 'fa fa-percentage',
						},
					],
				});
			}

			if (
				!isExcludeAllModules &&
				!this.excludeForecastModuleAccountIds.includes(accountId)
			) {
				items.push({
					name: 'Forecast',
					url: `/account/${accountId}/forecast`,
					icon: 'fa fa-binoculars',
				});
			}

			if (
				!isExcludeAllModules &&
				this.involveProjectTrackerList.includes(accountId)
			) {
				items.push({
					name: 'Project Tracker',
					url: `/account/${accountId}/project-tracker`,
					icon: 'fa fa-project-diagram',
				});
			}

			if (accountId === '3QcHw8jy23S7E1NWbcRL') {
				items.push(
					{
						name: 'Credit Limit',
						url: `/account/3QcHw8jy23S7E1NWbcRL/credit-limit`,
						icon: 'fa fa-dollar-sign',
					},
					{
						name: 'Reorder (System)',
						url: `/account/3QcHw8jy23S7E1NWbcRL/system-reorder`,
						icon: 'fa fa-shopping-basket',
					},
					{
						name: 'Reorder (Manual)',
						url: `/account/3QcHw8jy23S7E1NWbcRL/manual-reorder`,
						icon: 'fa fa-shopping-basket',
					},
					{
						name: 'Customer Aging',
						url: `/account/3QcHw8jy23S7E1NWbcRL/wls-customer-aging`,
						icon: 'fa fa-calendar-week',
					},
				);
			}

			if (accountId === 'AhhvdeCWa1iJJqPpgq26') {
				items.push({
					name: 'Stock Availability',
					url: `/account/AhhvdeCWa1iJJqPpgq26/eg-stock-availability`,
					icon: 'fa fa-boxes',
				});
			}
		}

		items.push({
			name: 'All Accounts',
			url: `/all-accounts`,
			icon: 'fa fa-book',
		});

		const adminMenu = [
			{
				title: true,
				name: 'Admin',
				class: '',
				wrapper: {
					element: '',
					attributes: {},
				},
			},
			{
				name: 'Manage Users',
				url: `/account/${
					this.fullAccountData && this.fullAccountData.accountId
				}/admin`,
				icon: 'fa fa-users',
			},
		];

		if (this.isAdmin) {
			items.push(...adminMenu);
		}

		const others = [
			{
				title: true,
				name: 'My Account',
				class: '',
				wrapper: {
					element: '',
					attributes: {},
				},
			},
			{
				name: 'Profile',
				url: `/profile`,
				icon: 'fa fa-user',
			},
			{
				name: 'Plan Details',
				url: `/plan-details`,
				icon: 'fa fa-calendar-alt',
			},
			{
				name: 'Reset Password',
				url: `/reset-password`,
				icon: 'fa fa-lock',
			},
		];
		items.push(...others);

  if (accountx.currentAccountId === 'HsJ2Zj1jFkQVkxOKmxvk') {
      items = [
        {
          name: 'Dealers',
          url: `/account/${accountx.currentAccountId}/dealer`,
          icon: 'fa fa-tachometer-alt',
        },
      ];

      items.push(...adminMenu, ...others);
    }

		return items;
	}
	public width: number = 0;

	// Exclude access to all modules
	public excludeAllAccountIdList: string[] = ['AhhvdeCWa1iJJqPpgq26'];

	// Exclude dashboard module
	public excludeDashboardModuleAccountIds: string[] = [];

	// Exclude sales module
	public excludeSalesModuleAccountIds: string[] = [];

	// Exclude agent module
	public excludeAgentModuleAccountIds: string[] = ['Cv7yI26SiPZ07zeUcWQQ'];

	// Exclude stock module
	public excludeStockModuleAccountIds: string[] = [];

	// Exclude customer module
	public excludeCustomerModuleAccountIds: string[] = ['Cv7yI26SiPZ07zeUcWQQ'];

	// Exclude purchase module
	public excludePurchaseModuleAccountIds: string[] = ['Cv7yI26SiPZ07zeUcWQQ'];

	// Exclude supplier module
	public excludeSupplierModuleAccountIds: string[] = [];

	// Exclude business performance module
	public excludeBPModuleAccountIds: string[] = ['Cv7yI26SiPZ07zeUcWQQ'];

	// Exclude forecast module
	public excludeForecastModuleAccountIds: string[] = ['Cv7yI26SiPZ07zeUcWQQ'];

	// Include project tracker module
	public involveProjectTrackerList: string[] = [
		'Eth0Uo5RMQobxFEltAA3',
		'lidNP3pv5YH88BLkA9JF',
    'BtbOPKLDiFhoSqCsxdSb',

	];
	// public created() {
	// 	// Checks for reloaded variable. If not true, reload happens. Else, no reload and reload in localStorage is removed
	// 	const reloaded = localStorage.getItem('reloaded');
	// 	if (reloaded) {
	// 		localStorage.removeItem('reloaded');
	// 	} else {
	// 		localStorage.setItem('reloaded', 'true');
	// 		location.reload();
	// 	}
	// }
	public onResize() {
		this.width = this.$el.clientWidth;
	}

	@Watch('currentAccountId', { immediate: true })
	public onCurrentAccountChange(val: string | null, oldVal: string | null) {
		redirectIfNeccessary(this.$route);
	}

	@Watch('isAdmin')
	public onIsAdminChange(val: string | null, oldVal: string | null) {
		redirectIfNeccessary(this.$route);
	}

	public beforeRouteEnter(
		to: Route,
		from: Route,
		next: (
			to?: RawLocation | false | ((vm: DefaultContainer) => any) | void,
		) => void,
	): void {
		if (to.params.accountId) {
			accountx.updateAccountId(to.params.accountId);
		}
		redirectIfNeccessary(to, next);
	}
	public beforeRouteUpdate(
		to: Route,
		from: Route,
		next: (
			to?: RawLocation | false | ((vm: DefaultContainer) => any) | void,
		) => void,
	): void {
		if (to.params.accountId) {
			accountx.updateAccountId(to.params.accountId);
		}
		redirectIfNeccessary(to, next);
	}
}

function redirectIfNeccessary(
	route: Route,
	next?: (
		to?: RawLocation | false | ((vm: DefaultContainer) => any) | void,
	) => void,
) {
	let path: string | undefined;
	// console.log(
	// 	'redirectIfNeccessary',
	// 	/^\/account(\/\w+)?\/?$/.test(route.path),
	// 	route.path,
	// );
	if (/^\/account(\/\w+)?\/?$/.test(route.path)) {
		// need to redirect
		if (accountx.currentAccountId !== 'loading') {
			// done extreacting proper current account id
			if (accountx.currentAccountId) {
				if (userx.supportLvl !== null && userx.supportLvl > 0) {
					path = `/account/${accountx.currentAccountId}/admin`;
				} else {
					path = `/account/${accountx.currentAccountId}/dashboard`;
				}
			} else {
				// user doesn't belong to any company account
				path = `/all-accounts`;
			}
		}
	} else if (/^\/account\/\w+\/admin\/?$/.test(route.path)) {
		if (
			accountx.currentAccountId !== 'loading' &&
			(!accountx.myPermissions || !accountx.myPermissions.admin)
		) {
			path = `/account/${accountx.currentAccountId}/dashboard`;
		}
	}
	// redirect
	if (next) {
		next(path);
	} else if (path && path !== route.path) {
		router.replace(path);
	}
}
