
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
	SidebarNavDivider,
	SidebarNavDropdown,
	SidebarNavLink,
	SidebarNavTitle,
	SidebarNavItem,
	SidebarNavLabel,
} from '@coreui/vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
@Component({
	components: {
		SidebarNavDivider,
		SidebarNavDropdown,
		SidebarNavLink,
		SidebarNavTitle,
		SidebarNavItem,
		SidebarNavLabel,
		VuePerfectScrollbar,
	},
})
export default class SidebarNav extends Vue {
	@Prop({ default: [], required: true }) public navItems!: any[];

	public get psSettings() {
		// ToDo: find better rtl fix
		return {
			maxScrollbarLength: 200,
			minScrollbarLength: 40,
			suppressScrollX:
				getComputedStyle(document.querySelector('html') as Element).direction !==
				'rtl',
			wheelPropagation: false,
			interceptRailY: (styles) => ({ ...styles, height: 0 }),
		};
	}
	public scrollHandle(evt) {
		// console.log(evt)
	}

}
