<template>
    <TheFooter>
        <div>
            <!--footer-->
            <span class="ml-1">Copyright &copy; {{ year }} </span>
            <a href="https://www.sql.com.my/" target="_blank">SQL</a>
            <span class="ml-4">{{`Version 2.${year}.${month}.1`}}</span>
        </div>
        <div class="ml-auto">
            <span class="mr-1">Powered by</span>
            <a href="https://www.sql.com.my/" target="_blank">SQL</a>
        </div>
    </TheFooter>
</template>
<script>
import { Footer as TheFooter } from '@coreui/vue';
import moment from 'moment';
export default {
    name: 'DefaultFooter',
    components: {
        TheFooter,
    },
    data() {
        return {
            year : moment().year(),
            month: moment().month() + 1,
        };
    },
};
</script>
